import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
     site {
        siteMetadata {
          hero
        }
      }
    }
  `)

  const { hero } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginTop: rhythm(1),
        marginBottom: rhythm(0.5),
      }}
    >
      <p>
        {hero}
      </p>
    </div>
  )
}

export default Hero
